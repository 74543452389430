<template>
  <form-field :name="field.name">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      lazy
      transition="scale-transition"
      min-width="290px"
    >
      <v-text-field
        slot="activator"
        v-model="field.value"
        :hint="hint"
        :rules="rules"
        v-bind="field.props"
        label="Enter date of birth"
        persistent-hint
        box
        prepend-icon="event"
        readonly
      />
      <v-date-picker
        ref="picker"
        v-model="field.value"
        :readonly="true"
        :allowed-dates="allowedDates"
        :max="max"
        :show-current="false"
        actions
        no-title
        @change="save"
      />
    </v-menu>
  </form-field>
</template>

<script>
import { DateTime, Duration, Interval } from 'luxon'
import formField from '@/mixins/form-field'

const aboveTwelve = 'Age 12 & above only'

export default {
  mixins: [formField],
  data () {
    return {
      date: null,
      menu: false,
      max: '',
      hint: aboveTwelve,
      rules: [val => !!val || 'Date of birth required!'],
    }
  },
  watch: {
    menu (val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  mounted: function () {
    if (this.field.props) {
      this.field.props = Object.assign({
      }, this.field.props)
    }
    this.rules.push(val => this.allowedDates(val) || aboveTwelve + '!')
  },
  methods: {
    allowedDates: function (date) {
      if (!date) return true
      const ymd = date.split('-')
      const whatDate = DateTime.local(Number(ymd[0]), Number(ymd[1]), Number(ymd[2])).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const duration = Duration.fromObject({ year: 12 })
      const interval = Interval.before(now, duration)
      this.max = interval.toFormat('yyyy-LL-dd')

      return interval.isAfter(whatDate)
    },
    save (date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>
